<template>
  <v-layout px-2 py-2 mt-1 row wrap v-if="!loading">
    <v-flex sm6 px-2>
      <a-form-view :value="data" :model="getFormModel(['room_type', 'name', 'description'])" :config="{ dense: true }" />
    </v-flex>
    <v-flex sm6 px-2>
      <a-form-view
        :value="data"
        :model="[
          ...getFormModel(['date_buy', 'vendor', 'article']),
          {
            name: 'amount',
            title: 'Кол-во',
            value: data.amount + ' ' + data.measure,
            type: 'string',
          },
          {
            name: 'price_info',
            value: price_info,
            title: 'Стоимость',
            type: 'string',
          },
        ]"
        :config="{ dense: true }"
      />
    </v-flex>
    <v-flex sm12>
      <a-form-view :value="data" :model="getFormModel(['link'])" :config="{ dense: true }" @click="onClick($event)" />
    </v-flex>
    <v-flex sm12>
      <a-view-images2 :value="data.photo" :size="200" />
    </v-flex>
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </v-layout>
</template>

<script>
import { autoHeightBlock, getAccess, popupMenu } from "../../components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu],
  components: {},
  props: {
    id: Number,
    dataTable: { type: Object, default: () => {} },
  },
  data() {
    return {
      showEditDialog: false,
      loading: false,
      title: "",
      model: this.$store.getters["config/get"].models.goods.form,
      url: "/mechti/construction-goods",
      blockHeight: 256,
      dataLocal: null,
    };
  },
  created() {
    this.fitchData();
  },
  mounted() {},

  computed: {
    data: {
      get() {
        if (this.dataTable) this.dataLocal = this.dataTable;
        else return null;
        this.dataTable.description = this.dataTable?.data?.description;
        this.dataTable.link = this.dataTable?.data?.link;
        this.dataTable.photo = this.dataTable?.data?.photo;
        return this.dataTable;
      },
      set(v) {
        this.dataLocal = v;
      },
    },

    photo: {
      get() {
        let res = this.data?.data?.photo || [];
        return res;
      },
    },
    link: {
      get() {
        let res = this.data?.data?.link || "";
        return res;
      },
    },
    description: {
      get() {
        let res = this.data?.data?.description || "";
        return res;
      },
    },
    price_info: {
      get() {
        const price = this.data?.price || "";
        const qty = this.data?.amount || "none";
        const price_unit = price / qty || "n/a";
        let res = qty !== 1 ? `${price_unit} * ${qty} = ${price}` || "none" : price;
        return res;
      },
    },
  },
  watch: {},
  methods: {
    async fitchData() {
      this.loading = true;
      this.loading = !true;
      return;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
        //        this.data.description = this.description;
        //        this.data.link = this.link;
        //        this.data.photo = this.photo;
      }
      this.loading = !true;
    },
    getFormModel(arr) {
      /* let res = this.model.filter((el) => {
        return arr.includes(el.name);
      }); */
      let res = [];
      arr.forEach((el) => {
        res = [
          ...res,
          this.model.filter((m) => {
            return m.name == el;
          })[0],
        ];
      });
      res.forEach((el) => {
        if (el.name == "link1") el.type = "link";
        if (!el?.type) {
          el.type = "text";
        }
      });

      return res;
    },
    onClick(e) {
      if (e.field?.isMenu) {
        this.showPopupMenu(e.field.name, e.row, e.event);
      }
    },
  },
};
</script>
